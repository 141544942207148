<template>
  <form @submit.prevent="completeAddress()">
    <div class="form-group">
      <label class="form-label" for="logo">{{ $t('components.register_address.logo') }}</label>
      <input id="logo" type="file" class="form-control form-control-logo" name="logo" @change="onFileChange($event)">
      <small class="form-text text-xs text-muted">{{ $t('form.image.format') }}<br>{{ $t('form.image.max_size') }}</small>
    </div>

    <div class="position-relative">
      <div class="form-group">
        <label for="job" class="form-label">{{ $t('components.job_field.label') }}</label>
        <div class="address-complete-job">
          <v-job-field
            :address="address"
            :index="0"
            @on-select-job="onSelectJob"
          />
          <v-sprite icon="enter" color="currentColor" />
          <div v-if="selectedJobs && selectedJobs.length > 0" class="selected-jobs">
            <div
              v-for="selectedJob in selectedJobs"
              :key="selectedJob.id"
              class="badge badge-light-blue selected-job"
              :title="selectedJob.name">
              <div class="selected-job-text">{{ selectedJob.name }}</div>
              <button type="button" class="selected-job-remove" @click.prevent="removeSelectedJob(selectedJob)">x</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <button
        :disabled="!isFormComplete"
        type="submit"
        :class="{ 'btn-light-blue': !isFormComplete, 'btn-blue': isFormComplete }"
        class="btn btn-rounded">
        {{ $t('components.address_complete_form.cta') }}
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import { HTMLInputEvent } from '../interfaces/events';
import Job from '../interfaces/job';
import { CompleteStep } from '../store/modules/complete';

export default defineComponent({
  name: 'AddressCompleteJobs',
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      selectedJobs: null as (Array<Job> | null),
      localLogo: null as (string | null),
    };
  },
  computed: {
    ...mapState('complete', [
      'address',
    ]),
    isFormComplete(): boolean {
      return !!(this.selectedJobs
      && this.selectedJobs.length > 0);
    },
  },
  watch: {},
  mounted() {},
  validations() {
    return {};
  },
  methods: {
    ...mapActions('complete', [
      'setAddress',
      'setStep',
    ]),
    reset() {
      this.selectedJobs = null;
    },

    // When the recruiter has selected a new job
    onSelectJob(value: Job): void {
      if (!this.selectedJobs) {
        this.selectedJobs = [];
      }

      this.selectedJobs.push(value);
    },
    removeSelectedJob(value: Job): void {
      const index = this.selectedJobs?.findIndex((selectedJob) => selectedJob.id === value.id) as number;

      if (index > -1) {
        this.selectedJobs?.splice(index, 1);
      }
    },
    onFileChange(e: HTMLInputEvent | DragEvent): void {
      const files = (e as HTMLInputEvent).target.files || (e as DragEvent).dataTransfer?.files;
      if (!files?.length) return;

      this.createImage(files[0]);
    },
    createImage(file: Blob): void {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.localLogo = (reader.result as string);
      });
      reader.readAsDataURL(file);
    },
    async completeAddress(): Promise<void> {
      try {
        let payload = {
          jobs: this.selectedJobs?.map((job) => job.id),
          logo: '',
        };

        if (this.localLogo) {
          payload = {
            ...payload,

            logo: this.localLogo
            ,
          };
        }

        await window.ky.patch(`/api/addresses/${this.address.uuid}/complete/`, { json: payload }).json<Address>();
        this.setStep(CompleteStep.COMPLETE);
      } catch {
        this.toast.error((this.$t('components.address_complete_form.error') as string));
      }
    },
  },
});
</script>

<style lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.address-complete-form {
  .svg-magnifier {
    bottom: 11px;
    margin: auto 0;
    position: absolute;
    right: 20px;
    top: auto;
  }
}

.address-complete-job {
  border: 1px solid $input-border-color;
  border-radius: 15px;

  .form-control {
    border: 0;
    box-shadow: none;
    padding: 15px 12px;
  }

  .svg-enter {
    bottom: auto;
    margin: auto 0;
    position: absolute;
    right: 20px;
    top: 50px;
  }
}

.selected-jobs {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 5px 10px;
}

.selected-job {
  border-radius: 15px;
  display: inline-flex;
  font-weight: 400;
  gap: 5px;
}

.selected-job-text {
  max-width: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected-job-remove {
  background: none;
  border: 0;
}

.form-control-logo {
  padding: 10px 12px;
}
</style>
