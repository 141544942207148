<template>
  <div>
    <label :for="`activitySector${index}`" class="form-label">{{ $t('components.register_address.activity_sector') }}</label>

    <div class="form-group">
      <input
        :id="`activitySector${index}`"
        v-model="activitySectorQuery"
        type="text"
        class="form-control"
        placeholder="Entrez votre secteur d'activité"
        autocomplete="off"
        @input="lookupActivitySector">
      <div v-if="activitySectors && activitySectorQuery && activitySectors.length > 0 && activitySectorQuery.length > 2" class="dropdown-menu show">
        <button
          v-for="activitySector in activitySectors"
          :key="activitySector.id"
          type="button" class="dropdown-item"
          @click.prevent="selectActivitySector(activitySector)">
          {{ activitySector.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useToast } from 'vue-toast-notification';

import ActivitySector from '@/js/interfaces/activity-sector';
import AddressRegistration from '@/js/interfaces/address-registration';

import PaginatedResult from '../interfaces/pagination';

export default defineComponent({
  name: 'ActivitySectorField',
  props: {
    address: {
      type: Object as PropType<AddressRegistration>,
      required: true,

    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['onSelectActivitySector'],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      activitySectors: [] as Array<ActivitySector>,
      activitySectorQuery: '',
    };
  },
  watch: {
    activitySectors() {
      this.initializeQuery();
    },
  },
  mounted() {
    this.getActivitySectors();
  },
  methods: {
    // Search an activity sector by name
    async lookupActivitySector(): Promise<void> {
      if (this.activitySectorQuery.length < 3) {
        if (this.activitySectorQuery.length === 0) this.reset();
        return;
      }

      await this.getActivitySectors();
    },
    selectActivitySector(activitySector: ActivitySector) {
      this.activitySectorQuery = activitySector.name;
      this.$emit('onSelectActivitySector', activitySector);
      this.activitySectors = [];
    },
    async getActivitySectors():Promise<void> {
      if (this.activitySectorQuery === '') {
        this.activitySectors = [];
        return;
      }

      const url = `/api/activity-sectors/?search=${this.activitySectorQuery}&page_size=6`;
      const data = await window.ky.get(url).json<PaginatedResult<ActivitySector>>();
      if (data.results) {
        this.activitySectors = data.results;
      }
    },
    initializeQuery():void {
      if (!this.address.activitySector) {
        return;
      }

      if (Number.isInteger(this.address.activitySector)) {
        const activitySector = this.activitySectors.find((a: ActivitySector) => a.id === this.address.activitySector);
        if (activitySector) {
          this.activitySectorQuery = activitySector.name;
        }
      } else {
        this.activitySectorQuery = (this.address.activitySector as ActivitySector).name;
      }
    },
    reset() :void {
      // Reset modal selection
      this.$emit('onSelectActivitySector', 0);
      this.activitySectorQuery = '';
      this.getActivitySectors();
    },
  },
});

</script>

<style scoped lang="scss">
@import '../../scss/config/config';

.form-group {
  position: relative;
}

.form-control {
  background-color: $white !important;
  padding-right: 60px;
}

.dropdown-menu {
  background-color: $white;
  padding: 10px;
  position: absolute;
  top: 38px;
  width: 100%;
  z-index: 10;
}

.dropdown-item {
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  display: block;
  line-height: $headings-line-height;
  padding: 10px;
  text-align: left;
  white-space: normal;
  width: 100%;

  &:not(:first-child) {
    border-color: $gray-300;
    border-width: 1px 0 0;
    box-shadow: inset 0 1px 2px rgb(0, 0, 0, .08);
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $light-blue;
    border-radius: 15px;
    color: $primary;
  }
}

</style>
