<template>
  <form v-if="localAddress" @submit.prevent="completeAddress()">
    <div class="position-relative form-group">
      <v-activity-sector-field
        :address="localAddress"
        :index="0"
        @on-select-activity-sector="onSelectActivitySector"
      />
      <v-sprite icon="magnifier" color="currentColor" />
    </div>

    <div class="form-group">
      <label class="form-label" for="address_content">{{ $t('components.address_complete_form.label.content') }}</label>
      <vue-bootstrap-autocomplete
        id="address_content"
        v-model="localAddressQuery"
        class="mb-1"
        :max-matches="5"
        :show-all-results="true"
        :data="mapboxAddresses"
        :serializer="(item: MapboxAddress) => item.place_name"
        placeholder="Ajoutez l’adresse de votre entreprise"
        @hit="onSelectAddress($event)"
        @input="lookupAddressHandler"
      />
    </div>

    <div class="mb-3 form-check">
      <input id="cgu" v-model="cgu" type="checkbox" class="form-check-input">
      <label class="form-check-label" for="cgu">{{ $t('components.address_complete_form.label.cgu') }}</label>
    </div>

    <div class="form-group">
      <button
        :disabled="!isFormComplete"
        type="submit"
        :class="{ 'btn-light-blue': !isFormComplete, 'btn-blue': isFormComplete }"
        class="btn btn-rounded">
        {{ $t('actions.next') }}
      </button>
    </div>
  </form>
</template>

<script lang="ts">
import { cloneDeep, DebouncedFuncLeading, throttle } from 'lodash';
import { defineComponent } from 'vue';
import { useToast } from 'vue-toast-notification';
import { mapActions, mapState } from 'vuex';

import ActivitySector from '../interfaces/activity-sector';
import Address from '../interfaces/address';
import MapboxAddress from '../interfaces/mapbox-address';
import { searchMapboxAddress } from '../services/mapbox';
import { CompleteStep } from '../store/modules/complete';

export default defineComponent({
  name: 'AddressCompleteForm',
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      localAddress: null as (Address | null),
      localAddressQuery: '',
      localActivitySectorId: undefined as (number | undefined),
      mapboxAddresses: [] as MapboxAddress[],
      lookupAddressHandler: throttle(this.searchAddress, 1000) as DebouncedFuncLeading<() => Promise<void>>,
      cgu: false as boolean,
    };
  },
  computed: {
    ...mapState('complete', [
      'address',
      'addressQuery',
    ]),
    isFormComplete(): boolean {
      return !!(this.localActivitySectorId
      && this.addressHasContent(this.address)
      && this.cgu);
    },
  },
  async mounted(): Promise<void> {
    this.localAddress = cloneDeep(this.address);
    this.localAddressQuery = this.addressQuery;
    this.localActivitySectorId = this.address.activitySector ? this.address.activitySector.id : null;

    await this.searchAddress();
    if (this.mapboxAddresses.length > 0) {
      this.onSelectAddress(this.mapboxAddresses[0]);
    }
  },
  methods: {
    ...mapActions('complete', [
      'setAddress',
      'setAddressQuery',
      'setStep',
    ]),
    addressHasContent(address: Address): boolean {
      return (Object.keys(address.content).length > 0 && address.content.constructor === Object);
    },
    onSelectActivitySector(activitySector: ActivitySector) {
      this.localActivitySectorId = activitySector.id;
    },
    async searchAddress(): Promise<void> {
      if (this.localAddressQuery && this.localAddressQuery.length > 2) {
        const localAddress = cloneDeep(this.address);
        localAddress.content = {} as MapboxAddress;
        this.setAddress(localAddress);

        const data = await searchMapboxAddress(this.localAddressQuery);
        this.mapboxAddresses = data.suggestions;
      } else {
        this.mapboxAddresses = [];
      }
    },
    onSelectAddress(selectedAddress: MapboxAddress): void {
      this.localAddressQuery = selectedAddress.place_name;
      this.setAddressQuery(this.localAddressQuery);

      const localAddress = cloneDeep(this.address);
      localAddress.content = selectedAddress;
      this.setAddress(localAddress);
    },
    async completeAddress(): Promise<void> {
      try {
        const payload = {
          activitySector: this.localActivitySectorId,
          content: this.address.content,
        };

        await window.ky.patch(`/api/addresses/${this.address.uuid}/complete/`, { json: payload }).json<Address>();
        this.setStep(CompleteStep.JOBS);
      } catch {
        this.toast.error((this.$t('components.address_complete_form.error') as string));
      }
    },
  },
});
</script>

<style lang="scss">
@import '../../scss/config/config';
@import 'sass-mq/mq';

.address-complete-form {
  .svg-magnifier {
    bottom: 11px;
    margin: auto 0;
    position: absolute;
    right: 20px;
    top: auto;
  }
}
</style>
