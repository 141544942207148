const messages = {
  actions: {
    add: 'Ajouter',
    update: 'Modifier',
    save: 'Enregistrer',
    validate: 'Valider',
    delete: 'Supprimer',
    continue: 'Continuer',
    cancel: 'Annuler',
    pay: 'Payer',
    login: 'Je me connecte',
    logout: 'Se déconnecter',
    signup: 'S\'inscrire',
    toggle: 'Basculer',
    download: 'Télécharger',
    reset_password: 'Réinitialiser le mot de passe',
    change_password: 'Modifier le mot de passe',
    change_email: 'Modifier l\'email',
    delete_account: 'Supprimer mon compte',
    contact_us: 'contactez-nous',
    access: 'Accéder',
    back: 'Retour',
    next: 'Suivant',
    next_step: 'Étape suivante',
    next_step_and_pay: 'Continuer et payer',
    finish: 'Terminer',
    send: 'Envoyer',
    finalize_payment: 'Finaliser le paiement',
  },
  job_card: {
    active: 'Actif',
    urgent: 'Urgent',
  },
  form: {
    required_field: 'Les champs signalés par une astérisque * sont obligatoires',
    required_field_short: '*champs obligatoires',
    helper: {
      password: 'Le mot de passe doit contenir un minimum de 8 caractères',
      phone_number: 'Obligatoire pour la livraison des affiches QRCode',
      address: 'Merci de renseigner l\'adresse postale précise.',
      address_name: '35 caractères maximum',
    },
    validation: {
      required: 'Ce champ est obligatoire',
      email: 'L\'adresse email doit être valide',
      same_as_email: 'Les emails doivent être identiques',
      same_as_password: 'Les mots de passe doivent être identiques',
      min_length: '8 caractères minimum',
      is_telephone: 'Le numéro de téléphone n\'est pas valide',
      full_name: 'Le champ doit comprendre votre prénom et votre nom',
      youtube_link: 'Merci d\'utiliser une url YouTube valide',
      file_size: 'Merci de choisir un fichier moins volumineux',
      image_file_format: 'Merci de choisir une fichier image (jpg, jpeg, png)',
    },
    image: {
      square: 'Format conseillé 1:1 (carré)',
      format: 'Formats acceptés : jpg, jpeg, png.',
      max_size: 'Poids maximum : 2 Mo',
      choose_file_text: 'Choisir un fichier',
    },
  },
  price: {
    with_taxes: 'TTC',
    without_taxes: 'HT',
  },
  views: {
    login: {
      title: 'Connexion',
      email: 'E-mail',
      password: 'Mot de passe',
      no_account: 'Vous n\'avez pas encore de compte ?',
      forgot_password: 'Mot de passe oublié ?',
      error: 'Impossible de se connecter, merci de réessayer.',
    },
    lost_password: {
      title: 'Mot de passe oublié',
      email: 'Email',
      error: 'Une erreur s\'est produite lors de la réinitialisation de votre mot de passe',
      success: 'Un email vous a été envoyé avec les instructions pour réinitialiser votre mot de passe.',
    },
    lost_password_confirm: {
      title: 'Mot de passe oublié',
      error: 'Une erreur s\'est produite lors de la réinitialisation de votre mot de passe',
      password: 'Mot de passe',
      password_repeat: 'Confirmez votre mot de passe',
      token: 'Code',
      success: 'Votre mot de passe a correctement été réinitialisé. Vous pouvez vous connecter.',
    },
    register_step_1: {
      title: 'Mon compte',
      subscription_title: 'Choisissez votre formule d’abonnement*',
      subscriptions: 'Abonnements',
      error: 'Impossible de créer votre compte',
      subscription_advantage_1: 'Appli candidats',
      subscription_advantage_2: 'QR-Code recruteur multi diffusion',
      subscription_advantage_3: 'Sourcing en continu 365 jours/an',
    },
    register_step_2: {
      title: 'Paiement',
      redirection_text: 'Vous allez être redirigé vers la page de paiement',
      payment_success: {
        title: 'decide.work vous remercie pour\nvotre abonnement',
        info: 'Retrouver votre facture dans la rubrique « mon compte »',
        dashboard_link: 'J’accède à mon espace decide.work',
      },
      payment_link_error: 'Une erreur s\'est produite. Veuillez réessayer ultérieurement.',
      shipping_info: 'Délais de livraison à J+7',
    },
    dashboard: {
      empty: 'Pour attirer et recevoir des candidatures, déclarez vos métiers et'
        + ' <strong>affichez votre QR Code recruteur</strong> sur vos lieux de passages.',
      welcome: 'Bienvenue dans votre compte decide.work',
      my_jobs: 'Mes métiers',
      add_a_job: 'Ajouter un métier',
      manage_my_jobs: 'Gérer mes métiers',
      all_resumes: 'Voir tous les CV',
      error: 'Une erreur s\'est produite lors de la récupération des données.',
      filter: 'Rechercher un nom',
      checkout_session_id: {
        success: 'Votre paiement a été accepté',
      },
    },
    account: {
      title: 'Mon compte',
      invoices: {
        title: 'Mes Factures',
        error: 'Une erreur s\'est produite lors de la récupération des données.',
        invoice_number: 'Numéro de facture',
        address: 'Adresse',
        date: 'Date',
        billing_reasons: {
          subscription_create: 'Création d\'abonnement',
          subscription_cycle: 'Renouvellement d\'abonnement',
          additional_product: 'Achat d\'étiquettes',
        },
        invoice_not_treated: 'En cours de traitement',
      },
      delete_account_modal: {
        title: 'Êtes vous sûr de vouloir supprimer votre compte?',
        message: 'Toutes vos données seront supprimées y compris vos candidatures. Vos abonnements seront résiliés.',
      },
    },
    jobs: {
      my_jobs: 'Mes métiers',
      add_job: 'Ajouter un métier',
      empty: 'Pour attirer les candidats, recensez les métiers de votre entreprise',
    },
    brand: {
      title: 'Ma marque employeur',
      description: 'Créez votre marque employeur par implantation pour donner plus d’authenticité à votre entreprise.',
      description2: 'Seul ce que vous renseignez est affiché côté candidat.',
      see_public_page: 'Voir ma page publique',
      save_modal: {
        title: 'Enregistrer les modifications ?',
        description: 'Si vous enregistrez, l’ancienne version de votre marque employeur ne sera donc pour disponible.',
      },
      confirm_modal: {
        title: 'Enregistrer les modifications ?',
        description: 'Souhaitez-vous sauvegarder vos modifications avant de quitter ?',
      },
      add: 'Ajouter un avantage',
    },
    brand_preview: {
      title: 'Ma marque employeur',
      description: 'Votre marque employeur est disponible côté candidat seulement sur l’application mobile.',
      description2: 'Les modifications peuvent être réalisées à n’importe quel moment.',
      back: 'Retour',
      values: 'Valeurs de l\'entreprise',
      advantages: 'Avantages',
      recruitment_steps: 'Notre processus de recrutement',
      card_title: 'Votre marque employeur',
      card_subtitle: 'visible dans l\'Appli candidat',
    },
    my_qr_codes: {
      my_qr_codes: 'Mon QR Code',
      attract_candidates: 'Attirez des candidats',
      contact_title: 'Affiche QR-Code sur mesure',
    },
    addresses: {
      title: 'Mes implantations',
      filter: 'Nom, ville ou code postal de l’implantation',
      subtitle: 'Gérez vos implantations',
      new_address: 'Renseigner une implantation',
      add_address: 'Ajouter une implantation',
      left: 'restantes',
      parent: 'Compte manager',
      new_address_form: {
        title: 'Renseigner une implantation',
        create: {
          success: 'L\'implantation a été créée',
          error: 'Une erreur s\'est produite. Veuillez réessayer ultérieurement.',
        },
      },
      add_address_form: {
        title: 'Ajout d’une implantation',
        error: 'Une erreur s\'est produite. Veuillez réessayer ultérieurement.',
      },
      create: {
        success: 'Implantation ajoutée avec succès',
        error: 'Une erreur s\'est produite. Veuillez réessayer ultérieurement.',
      },
    },
    resume_list: {
      title: 'Ma CVthèque',
      search_bar_placeholder: 'Rechercher un nom',
      selected_filters: 'Filtre(s) sélectionné(s) :',
      extra: 'Afficher par',
      date_filter_favorite: 'Favoris',
      date_filter_7: '7 derniers jours',
      date_filter_14: '14 derniers jours',
      date_filter_30: '30 derniers jours',
      empty: 'Une fois votre QR-CODE affiché et scanné par les candidats, les profils apparaitront dans votre CVthèque.',
      no_search_results: 'Aucun résultat pour votre recherche',
    },
    resume: {
      return_to_resumes_mobile: 'Retour',
      return_to_resumes: 'Retour à la CVthèque',
      job_selected: 'Métier sélectionné',
      documents: 'Document(s) joint(s)',
      experience: 'Expérience sur le métier de',
      experience_without_job: 'Expérience',
      last_jobs: 'Derniers emplois exercés',
      degrees: 'Niveau d’étude',
      languages: 'Langues',
      licences: 'Permis',
      locomotion: 'Mobilité',
      error: 'Une erreur s\'est produite lors de la récupération des données.',
      copy_profile_link: 'Copier le lien du profil',
    },
    resume_filters: {
      error: 'Une erreur s\'est produite lors de la récupération des filtres.',
    },
    applications: {
      error: 'Une erreur s\'est produite lors de la récupération des données.',
    },
  },
  components: {
    ask_address_form: {
      number: 'Nombre d’implantation supplémentaire',
      email: 'Adresse mail',
      message: 'Message (optionnel)',
      placeholder: {
        message: 'Veuillez nous indiquer votre demande',
      },
      success: 'Votre message a été envoyé correctement.',
      error: 'Il y a une erreur, merci de réessayer ultérieurement',
    },
    address_complete_form: {
      label: {
        job: 'Entrez le(s) métier(s) de votre entreprise*',
        content: 'Adresse de l\'entreprise*',
        cgu: 'En cochant cette case, j\'atteste que les informations fournies sont exactes et complètes.',
      },
      cta: 'Valider mon inscription',
      error: 'Il y a une erreur, merci de réessayer ultérieurement',
    },
    address_complete_siret_form: {
      label: {
        siret: 'Numéro SIRET*',
      },
    },
    job_field: {
      label: 'Entrez le(s) métier(s) de votre entreprise*',
    },
    subscription_card: {
      month: 'mois',
      choose: 'Démarrer',
      popular: 'Populaire',
      per_month: ' / par mois',
      paying_duration_1_title: 'Essentiel 1 mois',
      paying_duration_12_title: 'Essentiel 12 mois',
      offered_product: '{quantity} QRCode autocollant {name} {description} offert',
      save: 'Économisez {percent}%',
    },
    register_address: {
      title: 'Adresse d\'affichage des QRCodes :',
      description: 'Information à renseigner pour être visible sur votre QR code Recruteur.',
      name: {
        label: 'Nom de l\'entreprise sur le QR Code*',
        placeholder: 'Ex: decide siège, decide Bordeaux, decide Paris...',
      },
      logo: 'Télécharger un logo / une image (optionnel)',
      content: {
        label: 'Adresse de l’implantation*',
        placeholder: 'Entrez l\'adresse de votre entreprise',
      },
      activity_sector: 'Secteur d\'activité*',
      select_activity_sector: 'Sélectionnez un secteur d\'activité',
      receive_qr_code: 'Je souhaite recevoir le pack de QR codes autocollants pour cette adresse',
      deny_receive_qr_code: 'Si vous ne choisissez pas les QR Codes autocollants, '
        + 'vous recevrez vos QR Codes en pdf par mail à la fin de votre commande',
      additional_order: 'Je commande des QR Codes recruteur supplémentaires',
      local_resume_librairies: 'Dotez chaque implantation de sa propre CVthèque locale',
      email: 'Adresse e-mail de l’implantation*',
      receive_application_by_email: 'Candidatures par mail',
      frequency: {
        daily: 'Mail quotidien',
        weekly: 'Mail hebdomadaire',
      },
      qrcode_town: {
        label: 'Ville affichée sur le QR Code*',
        placeholder: 'Entrez la ville affichée sur votre QR Code',
      },
    },
    register_address_product: {
      unit_price: 'Prix unitaire',
    },
    register_breadcrumb: {
      step1: 'Mon compte',
      step2: 'Paiement',
    },
    register_summary: {
      subscription_s: 'Abonnement',
      title: 'Récapitulatif',
      qrcode: 'QR codes',
      quantity: 'Quantité : ',
      total: 'Total',
      subscription_bonuses: 'offert par abonnement',
    },
    register_information: {
      manager: {
        label: 'Nom complet du responsable',
        placeholder: 'Entrez le nom du responsable',
      },
      phone_number: {
        label: 'Numéro de téléphone',
        placeholder: 'Entrez votre numéro de téléphone',
      },
      address: {
        label: 'Adresse',
        placeholder: 'Entrez l\'adresse de votre entreprise',
      },
      email: {
        label: 'Email',
        placeholder: 'Entrez l\'adresse email de l\'entreprise',
      },
      password: {
        label: 'Mot de passe',
        placeholder: 'Entrez votre mot de passe',
      },
      password_repeat: {
        label: 'Confirmez votre mot de passe',
        placeholder: 'Entrez votre mot de passe',
      },
      logo: 'Logo',
    },
    app_navigation: {
      home: 'Accueil',
      addresses: 'Mes implantations',
      jobs: 'Mes métiers',
      brand: 'Ma marque employeur',
      my_qr_codes: 'Mon QR code',
      resumes: 'Ma CVthèque',
      account: 'Mon compte',
      back: 'Retour',
      logout: 'Se déconnecter',
    },
    store_switch: {
      filter: "Nom, ville ou code postal de l'entreprise",
      manage_addresses: 'Gérer mes implantations',
      my_qr_codes: 'Mes QR Codes',
      subscription_is_over: 'Votre abonnement pour cette adresse est terminé',
      qr_code_download: {
        error: 'Une erreur s\'est produite lors du téléchargement de votre code QR.',
      },
      qr_code_print: {
        error: 'Une erreur s\'est produite lors de l\'impression de votre code QR.',
      },
      tooltip: {
        qr_code_download: 'Imprimez votre QR Code Recruteur',
        qr_code_download_small: 'Téléchargez votre QR code recruteur',
        cart: 'Commandez votre QR-Code en affiches autocollantes',
      },
      offcanvas: {
        qr_code_download: 'Imprimez l\'affiche',
        qr_code_download_small: 'Téléchargez le QR Code',
        cart: 'Commandez les stickers',
      },
      no_subscription: 'Pas d\'abonnement pour cette adresse',
      attract_candidates: 'Attirez des candidats',
    },
    account_form: {
      company: 'Entreprise',
      lastname: 'Nom',
      firstname: 'Prénom',
      success: 'Votre compte a été mis à jour.',
      address_helper: 'Seules les adresses françaises sont acceptées.',
      error: 'Une erreur s\'est produite lors de la mise à jour de votre compte.',
    },
    new_job_form: {
      job_title: 'Ajout d\'un métier',
      job_label: 'Entrez l\'intitulé du métier*',
      job_placeholder: 'ex : vendeur / vendeuse',
      monthly_salary_label: 'Salaire brut mensuel (optionnel)',
      monthly_salary_placeholder: 'Indiquez le salaire brut mensuel',
      success: 'Le métier a été ajouté.',
      error: 'Une erreur s\'est produite lors de l\'ajout du métier.',
      job_not_found: 'Si vous n \'avez pas trouvé votre métier ',
      contract_label: 'Type de contrat',
      working_time_label: 'Temps de travail',
      remote_label: 'Télétravail',
    },
    edit_job_form: {
      job_label: 'Entrez l\'intitulé du métier*',
      job_placeholder: 'ex : vendeur / vendeuse',
      monthly_salary_label: 'Salaire brut mensuel (optionnel)',
      monthly_salary_placeholder: 'Indiquez le salaire brut mensuel',
      success: 'Le métier a été modifié.',
      error: 'Une erreur s\'est produite lors de la modification du métier.',
      job_not_found: 'Si vous n \'avez pas trouvé votre métier ',
      incorrect_salary_format: 'entrez un format de salaire correct',
    },
    job_card: {
      delete_success: 'Le métier a été supprimé.',
      delete_error: 'Une erreur s\'est produite lors de la suppression du métier.',
      update_error: 'Une erreur s\'est produite lors de la mise à jour du métier.',
      active: 'Actif',
      urgent: 'Recherche urgente',
    },
    free_application_card: {
      title: 'Candidature libre',
      update: {
        error: 'Il y a eu une erreur pendant la mise à jour de la candidature libre',
      },
    },
    manage_address: {
      label: {
        name: 'Nom de l\'entreprise / Boutique / Agence / Marque',
        logo: 'Télécharger un logo / une image (optionnel)',
        content: 'Adresse du lieu d\'implantation qui recrute',
        activity_sector: 'Secteur d\'activité',
      },
      cta_print: 'Imprimer mon QR Code',
      cta_print_small: 'Télécharger mon QR Code',
      no_file_chosen: 'Aucun fichier choisi',
      btn_file_upload: 'Ajouter',
      label_qr_code_sticker: 'Commandez votre QR-Code en format affiches autocollantes',
      btn_qr_code_sticker: 'Commander',
      label_stop_subscription: 'Ne pas renouveler cet abonnement',
      link_stop_subscription: 'Contactez-nous',
    },
    address_card: {
      active: 'Actif',
      manage: 'Modifier',
      delete: 'Supprimer',
      delete_modal: {
        title: 'Confirmez-vous la suppression de {name} ?',
        content: 'Si vous confirmez, {name} sera définitivement effacé de vos implantations.',
        success: 'Votre adresse a été supprimée',
        error: 'Il y a eu une erreur lors de la suppression de votre adresse',
      },
      update: {
        success: 'Votre adresse a été mise à jour',
        error: 'Il y a eu une erreur lors de la mise à jour de votre adresse',
      },
    },
    resumes_filters: {
      all: 'Tous',
      label: 'Filtrer par :',
      jobs: 'Métiers',
      experience: 'Expérience',
    },
    change_password_form: {
      cta: 'Modifier mon mot de passe',
      old_password: 'Ancien mot de passe',
      new_password: 'Nouveau mot de passe',
      new_password_repeat: 'Nouveau mot de passe (confirmation)',
      success: 'Votre mot de passe a été modifié',
      error: 'Il y a eu une erreur lors de la modification de votre mot de passe',
    },
    change_email_form: {
      cta: 'Modifier mon email',
      old_email: 'Ancien email',
      new_email: 'Nouveau email',
      new_email_repeat: 'Nouveau email (confirmation)',
      success: 'Votre email a été modifié',
      error: 'Il y a eu une erreur lors de la modification de votre email',
    },
    delete_account: {
      error: 'Il y a eu une erreur lors de la suppresssion de votre compte.',
    },
    download_invoice: {
      error: 'Il y a eu une erreur lors du téléchargement de votre facture.',
    },
    brand_navigation: {
      advantages: 'Avantages',
      recruitment: 'Processus de recrutement',
      media: 'Photos et vidéo',
      values: 'Valeurs',
    },
    brand_advantages: {
      description: 'Sélectionnez les avantages dont bénéficieront vos collaborateurs.',
      other: 'Autre',
      save: {
        success: 'Avantages enregistrés avec succès',
        error: 'Il y a eu un problème pendant la mise à jour des avantages',
      },
      delete: {
        success: 'Avantage supprimé avec succès',
      },
      add: 'Ajouter un avantage',
      name: 'Nom',
    },
    brand_recruitment: {
      description: 'Décrivez le processus de recrutement généralement suivi dans votre entreprise.',
      hint: '(6 étapes maximum)',
      errors: {
        duplicates: 'Vous avez choisi plusieurs fois la même étape, merci de sélectionner des étapes uniques.',
      },
      save: {
        success: 'Processus de recrutement mis à jour avec succès',
        error: 'Il y a eu un problème pendant la mise à jour du processus de recrutement',
      },
    },
    brand_media: {
      description: 'En ajoutant les photos de vos locaux, bureaux, points de vente et de vos équipes, '
        + 'vous aidez les candidats à se projeter dans leur futur environnement de travail.',
      youtube_link: 'Lien de votre vidéo youtube',
      youtube_link_placeholder: 'Entrez le lien de votre vidéo youtube',
      pictures: 'Autres photos',
      pictures_hint: '(3 maximum)',
      image: {
        format: 'Format accepté : JPG/PNG/SVG',
        max_size: 'Poids max : 2 Mo',
      },
      save: {
        success: 'Photos et video mis à jour avec succès',
        error: 'Il y a eu un problème pendant la mise à jour des photos et vidéo',
      },
      errors: {
        size: 'Merci de renseigner une photo de 2Mo maximum',
        format: 'Merci de renseigner un fichier image',
      },
    },
    brand_values: {
      description: 'Attirez les candidats en partageant vos valeurs. '
        + 'Les candidats d’aujourd’hui demandent plus de transparence sur l’entreprise avant même de candidater.',
      label: 'Les valeurs de votre entreprise',
      hint: 'caractères restants',
      placeholder: 'Entrez ici les valeurs de votre entreprise...',
      save: {
        success: 'Valeurs mises à jour avec succès',
        error: 'Il y a eu un problème pendant la mise à jour des valeurs',
      },
    },
    qrcode_contact_form: {
      email: 'Adresse email',
      message: 'Message',
      success: 'Votre message a été envoyé correctement.',
      error: 'Il y a une erreur, merci de réessayer ultérieurement',
      placeholder: {
        message: 'Veuillez nous indiquer votre demande',
      },
    },
  },
  modals: {
    confirm_order: {
      title: 'Merci pour votre achat',
      message: 'Votre facture sera bientôt disponible sous l\'onglet \'Mon compte\'.',
    },
    confirm_logout: {
      title: 'Voulez-vous vraiment vous déconnecter ?',
    },
    new_advantage: {
      title: 'Ajout d’un avantage',
      placeholder: 'Ex: Sensible à l\'environnement',
      max_length: '50 caractères maximum',
    },
  },
  footer: {
    download_app: 'Vous êtes à la recherche d’un emploi, télécharger l’application',
  },
};

export default messages;
