import Cookies from 'js-cookie';
import { ActionContext } from 'vuex';

import Address from '@/js/interfaces/address';
import { ApiTokenResponse } from '@/js/interfaces/api';
import Information from '@/js/interfaces/information';
import Job from '@/js/interfaces/job';
import PaginatedResult from '@/js/interfaces/pagination';
import {
  AddressQueryPayload,
  AddressUpdatePayload,
  ChangeEmailPayload,
  ChangePasswordPayload, JobUpdatePayload,
  LoginPayload,
  ResetPasswordConfirmPayload,
  ResetPasswordPayload, ToggleActiveAddressPayload,
} from '@/js/interfaces/payload';
import Recruiter from '@/js/interfaces/recruiter';
import State from '@/js/store/state';

interface RecruiterState {
  access?: string|null,
  refresh?: string|null,
  user?: Recruiter|null,
  addresses: Array<Address>,
  loggedIn: boolean,
  loginError: string|null,
  currentAddress?: Address|null,
  parentAddress?: Address|null,
  subscriptionStatus: string,
  jobs: Array<Job>;
}

const defaultState: RecruiterState = {
  access: null,
  refresh: null,
  user: null,
  addresses: [],
  loggedIn: false,
  loginError: null,
  currentAddress: null,
  parentAddress: null,
  subscriptionStatus: '',
  jobs: [],
};

type Context = ActionContext<RecruiterState, State>;

const mutations = {
  setAccess(state: RecruiterState, access: string): void {
    state.access = access;
  },

  setRefresh(state: RecruiterState, refresh: string): void {
    state.refresh = refresh;
  },

  setUser(state: RecruiterState, user: Recruiter): void {
    state.user = user;
  },

  setAddresses(state: RecruiterState, addresses: Array<Address>): void {
    state.addresses = addresses;
  },

  setCurrentAddress(state: RecruiterState, address: Address): void {
    state.currentAddress = address;
  },

  setParentAddress(state: RecruiterState, address: Address): void {
    state.parentAddress = address;
  },

  setSubscriptionStatus(state: RecruiterState, status: string): void {
    state.subscriptionStatus = status;
  },

  setJobs(state: RecruiterState, jobs: Array<Job>): void {
    state.jobs = jobs;
  },

  loginSuccess(state: RecruiterState): void {
    state.loggedIn = true;
    state.loginError = null;
  },

  loginFailure(state: RecruiterState, errorMessage: string): void {
    state.loggedIn = false;
    state.loginError = errorMessage;
  },

  logout(state: RecruiterState): void {
    state.access = '';
    state.refresh = '';
    state.loggedIn = false;
    state.user = null;
  },

  toggleActiveAddress(state: RecruiterState, payload: ToggleActiveAddressPayload): void {
    if (state.currentAddress && state.currentAddress.uuid === payload.uuid) {
      state.currentAddress.active = payload.active;
    }
  },
};

const actions = {
  async register(context: Context, payload: Information): Promise<void> {
    await window.ky.post('/api/recruiter/', { json: payload }).json<Recruiter>();
  },

  async getProfile(context: Context): Promise<void> {
    const data = await window.ky.get('/api/recruiter/me/profile/').json<Recruiter>();

    if ('recruiter' in data && data.recruiter !== null) {
      context.commit('setAccess', Cookies.get('jdmcv_access'));
      context.commit('setRefresh', Cookies.get('jdmcv_refresh'));
      context.commit('setUser', data);
      context.commit('loginSuccess');

      await context.dispatch('getAddresses', { page: 1 });
    }
  },

  async getAddresses(context: Context, payload: AddressQueryPayload): Promise<PaginatedResult<Address>> {
    const searchParameters = new URLSearchParams(payload as Record<string, string>);
    let url = '/api/addresses/';

    if (searchParameters.size > 0) {
      url += `?${searchParameters.toString()}`;
    }

    const data = await window.ky.get(url).json<PaginatedResult<Address>>();
    if ('results' in data && data.results !== null && !context.state.currentAddress) {
      await context.dispatch('setAddresses', data.results);
      await context.dispatch('setCurrentAddress', data.results[0]);
    }

    return data;
  },

  setAddresses(context: Context, addresses: Array<Address>): void {
    context.commit('setAddresses', addresses);
  },

  async getParentAddress(context: Context): Promise<void> {
    const url = '/api/addresses/parent/';

    const data = await window.ky.get(url).json();
    context.commit('setParentAddress', data);
  },

  async deleteProfile(context: Context): Promise<void> {
    try {
      await window.ky.delete('/api/recruiter/me/delete/').json();
      await context.dispatch('logout');
    } catch (error) {
      console.error(error);
    }
  },

  async setCurrentAddress(context: Context, address: Address): Promise<void> {
    context.commit('setSubscriptionStatus', address.subscription?.status);

    const data = await window.ky.get(`/api/addresses/${address.uuid}/`).json<Address>();
    context.commit('setCurrentAddress', data);
    context.commit('setJobs', data.jobs);
  },

  async setJobs(context: Context, jobs: Array<Job>): Promise<void> {
    context.commit('setJobs', jobs);
  },

  setUser(context: Context, user: Recruiter): void {
    context.commit('setUser', user);
  },

  setAccess(context: Context, access: string): void {
    context.commit('setAccess', access);
    Cookies.set('jdmcv_access', access, { secure: true, sameSite: 'strict', expires: 14 });
  },

  setRefresh(context: Context, refresh: string): void {
    context.commit('setRefresh', refresh);
    Cookies.set('jdmcv_refresh', refresh, { secure: true, sameSite: 'strict', expires: 365 });
  },

  async fetchJobs(context: Context): Promise<void> {
    if (context.state.currentAddress) {
      const data = await window.ky.get(`/api/addresses/${context.state.currentAddress.uuid}/`).json();
      context.commit('setJobs', data.jobs);
    }
  },

  async updateJob(context: Context, payload: JobUpdatePayload): Promise<void> {
    await window.ky.patch(`/api/addresses-jobs/${payload.uuid}/`, { json: payload.data });
    setTimeout(async () => {
      await context.dispatch('fetchJobs');
    }, 300);
  },

  async updateAddress(context: Context, payload: AddressUpdatePayload): Promise<void> {
    await window.ky.patch(`/api/addresses/${payload.uuid}/`, { json: payload.data });
  },

  async toggleActiveAddress(context: Context, payload: ToggleActiveAddressPayload): Promise<void> {
    await window.ky.patch(`/api/addresses/${payload.uuid}/`, { json: { active: payload.active } });
  },

  async deleteJob(context: Context, uuid: string): Promise<void> {
    await window.ky.delete(`/api/addresses-jobs/${uuid}/`).json<void>();
    await context.dispatch('fetchJobs');
  },

  async login(context: Context, payload: LoginPayload): Promise<void> {
    try {
      const data = await window.ky.post('/api/token/', { json: payload }).json<ApiTokenResponse>();
      const { access, refresh } = data;
      await context.dispatch('setAccess', access);
      await context.dispatch('setRefresh', refresh);
      await context.dispatch('getProfile');

      context.commit('loginSuccess');
    } catch (error) {
      await context.dispatch('setAccess', null);
      await context.dispatch('setRefresh', null);
      await context.dispatch('loginFailure', error ?? 'Unknown error');
    }
  },

  logout(context: Context) {
    context.commit('logout');
    // Remove tokens from cookies
    Cookies.remove('jdmcv_access');
    Cookies.remove('jdmcv_refresh');
  },

  async resetPassword(context: Context, payload: ResetPasswordPayload): Promise<void> {
    await window.ky.post('/api/password-reset/', { json: { email: payload.email } }).json<void>();
  },

  async resetPasswordConfirm(context: Context, payload: ResetPasswordConfirmPayload): Promise<void> {
    const json = { token: payload.token, password: payload.password };
    await window.ky.post('/api/password-reset/confirm/', { json }).json<void>();
  },

  async changePassword(context: Context, payload: ChangePasswordPayload): Promise<void> {
    const json = { oldPassword: payload.oldPassword, newPassword: payload.newPassword };
    await window.ky.patch('/api/change-password/', { json }).json<void>();
  },

  async changeEmail(context: Context, payload: ChangeEmailPayload): Promise<void> {
    const json = { oldEmail: payload.oldEmail, newEmail: payload.newEmail };
    await window.ky.patch('/api/change-email/', { json }).json<void>();
  },

  async goToParentAddress(context: Context): Promise<void> {
    await context.dispatch('getParentAddress');

    if (context.state.parentAddress) {
      context.commit('setCurrentAddress', context.state.parentAddress);
    }
  },
};

const getters = {
  subscriptionIsActive(state: RecruiterState) {
    return state.subscriptionStatus === 'active';
  },
  customAdvantages(state: RecruiterState) {
    return state.currentAddress?.advantages.filter((advantage) => advantage.category === null);
  },
  isPremium(state: RecruiterState) {
    return state.user?.recruiter.maxAddresses ? state.user?.recruiter.maxAddresses > 0 : false;
  },
};

const recruiter = {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};

export default recruiter;
